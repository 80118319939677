import {
  defaultCatchHandler,
  QueryByOrgId,
} from '@ax/data-services-common/queries';
import { success } from '@ax/type-utils';
import { makeQueryable } from '@ax/cache-and-dedupe-vue';
import { BaseHTTPClient } from '@ax/data-services-common/client';
import { Dashboard } from '@ax/data-services-reporting/models';

const DASHBOARD_ENDPOINT = '/dashboard';

export class GetDashboardByOrgId extends QueryByOrgId<Dashboard> {
  type = 'GetDashboardByOrgId';
}

export function fetchDashboardByOrgId(orgId: number) {
  return BaseHTTPClient.get(`${DASHBOARD_ENDPOINT}`, {
    params: {
      o: orgId,
      internal: 1,
    },
  })
    .then(({ data }) => success(data))
    .catch(defaultCatchHandler);
}

export const { useQuery: useDashboardQuery } = makeQueryable(
  ({ orgId }: GetDashboardByOrgId) => fetchDashboardByOrgId(orgId),
);
